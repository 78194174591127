<template>
    <div>
        <div class="page-content-box">
            <div class="page-filter-box">
                <el-row :gutter="16">
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-input v-model="params.value" class="input-with-select" size="small">
                            <el-select v-model="params.type" slot="prepend" placeholder="请选择">
                                <el-option label="商品ID" :value="1"></el-option>
                                <el-option label="商品名称" :value="2"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-select v-model="params.status" placeholder="请选择状态" size="small">
                            <el-option value="" label="不限制状态"></el-option>
                            <el-option :value="3" label="仅看待上架的"></el-option>
                            <el-option :value="1" label="仅看销售中的"></el-option>
                            <el-option :value="0" label="仅看已下架的"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-select v-model="params.categoryId" placeholder="请选择类别" size="small">
                            <el-option value="" label="不限制类别"></el-option>
                            <el-option :value="1" label="仅看唱片专辑"></el-option>
                            <el-option :value="2" label="仅看服饰"></el-option>
                            <el-option :value="3" label="仅看纪念品"></el-option>
                            <el-option :value="5" label="仅看配饰"></el-option>
                            <el-option :value="4" label="仅看其他"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                        <el-button type="primary" @click="search" size="small">搜索</el-button>
                        <el-button @click="reset" size="small">重置</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="page-list-box">
                <el-table
                    :data="dataList"
                    style="width: 100%"
                    v-loading="listLoading"
                    size="small"
                    header-row-class-name="page-list-table"
                >
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == -1" class="color-info">已删除</span>
                            <span v-if="scope.row.status == 0" class="color-danger">已下架</span>
                            <span v-if="scope.row.status == 1" class="color-success">销售中</span>
                            <span v-if="scope.row.status == 2">已售罄</span>
                            <span v-if="scope.row.status == 3" class="color-warning">待上架</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="商品ID"></el-table-column>
                    <el-table-column prop="name" label="商品名称" min-width="200" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="categoryId" label="类别">
                        <template slot-scope="scope">{{categoryId[scope.row.categoryId]}}</template>
                    </el-table-column>
                    <el-table-column prop="priceFormat" label="价格"></el-table-column>
                    <el-table-column prop="balanceType" label="结算方式">
                        <template slot-scope="scope">
                            <span v-if="scope.row.balanceType == 1">自营-预付款</span>
                            <span v-else>代销-后付款</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="totalStock" label="总库存"></el-table-column> -->
                    <el-table-column prop="sellStock" label="已销售"></el-table-column>
                    <el-table-column prop="remainStock" label="剩余"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button
                                v-if="scope.row.status == 0 || scope.row.status == 3"
                                type="text"
                                size="small"
                                @click="offShelves(scope.row.id,1)"
                                >上架</el-button
                            >
                            <el-button
                                v-if="scope.row.status == 1 || scope.row.status == 2"
                                type="text"
                                size="small"
                                @click="offShelves(scope.row.id,0)"
                                >下架</el-button
                            >
                            <el-button
                                type="text"
                                size="small"
                                @click="showCode(scope.row)"
                                >商品链接/二维码</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-if="data" class="page-pagination-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>
        </div>
        <goods-code ref="goodsCode"></goods-code>
    </div>
</template>
<script>
import GoodsCode from "./components/goods-code";
const defaultParams = {
    pageNo: 1,
    pageSize: 15,
    categoryId: "",
    status: "",
    type: 2,
    value: ""
}
export default {
    components:{GoodsCode},
    data(){
        return{
            data: null,
            dataList: null,
            params:{
                ...defaultParams
            },
            listLoading: true
        }
    },
    computed:{
        categoryId(){
            return {
                1: '唱片专辑',
                2: '服饰',
                3: '纪念品',
                4: '其他',
                5: '配饰'
            }
        }
    },
    created(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/shopGood/list",
                method: "POST",
                data: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pageNo = 1;
            this.getDataList();
        },
        //重置
        reset(){
            this.params = {
                ...defaultParams,
                pageSize: this.params.pageSize
            }
            this.getDataList();
        },
        //查看二维码
        showCode(obj){
            this.$refs.goodsCode.show(obj);
        },
        //上下架
        offShelves(id,type){
            const _msgTitle = type == 0 ? '确认下架' : '确认上架';
            const _msg = type == 0 ? '下架后商品不再销售，是否确认下架该商品？' : '是否确认上架开始销售该商品？';
            this.$confirm(_msg, _msgTitle, {
                type: 'warning',
                confirmButtonText: _msgTitle,
                customClass:'mpop',
                closeOnClickModal: false,
                closeOnPressEscape: false
            }).then( () => {
                this.listLoading = true;
                this.$request({
                    url: '/shopGood/offShelves',
                    method: 'POST',
                    params: {id:id}
                }).then(res => {
                    const { state, msg } = res.data;
                    if( state == 1 ){
                        this.getDataList();
                    }else{
                        this.$message.error(msg || '请求失败');
                    }
                }).catch(() => {
                    this.$message.error('请求错误');
                }).finally(() => {
                    this.listLoading = false;
                });
            }).catch(() => {});
        }
    }
}
</script>

<style lang="scss" scoped>

</style>