<template>
     <el-dialog
        title="推广二维码"
        :visible.sync="dialogVisible"
        center
        @close="close">
        <div v-if="codeData" class="dialog-content">
            <h2 class="name">{{codeData.name}}</h2>
            <div class="tips color-info">使用以下二维码和链接推广您的商品</div>
            <div class="code">
                <vue-qr :logoSrc="logo" :text="codeData.qrCodeUrl" :size="800" :logoMargin="8" logoBackgroundColor="#ffffff" :callback="getImage" :margin="0"></vue-qr>
            </div>
            <a :href="codeData.qrCodeUrl" target="_blank" class="url">{{codeData.qrCodeUrl}}</a>
            <input class="copyTool" ref="copyTool" :value="codeData.qrCodeUrl" />
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="copy">复制链接</el-button>
            <el-button type="primary" size="medium" @click="downloadCode">下载二维码</el-button>
        </span>
    </el-dialog>
</template>

<script>
import logo from '@/assets/img/logo2x.png';
import VueQr from 'vue-qr'

export default {
    components:{
        VueQr
    },
    data(){
        return{
            dialogVisible: false,
            codeData:null,
            logo:logo,
            codeImage:'',
            copyTimer: null,
            sswap:process.env.VUE_APP_SSWAP_URL
        }
    },
    methods:{
        async show(obj){
            this.codeData = {
                ...obj,
                qrCodeUrl:this.sswap+'/pages/goods/detail/detail?id='+obj.id+'&ssfrom=user-'+this.$store.state.userInfo.id
            };
            this.dialogVisible = true;
        },
        close(){
            this.dialogVisible = false
        },
        getImage(url){
            this.codeImage = url;
        },
        downloadCode(){
            const _data = this.$utils.base64ToBlob(this.codeImage);
            this.$utils.exportFile(_data,`${this.codeData.name}的二维码.png`);
        },
        //复制链接
        copy(){
            clearTimeout(this.copyTimer);
            this.copyTimer = setTimeout(() => {
                const e = this.$refs.copyTool;
                e.select();
                document.execCommand("Copy"); // 执行浏览器复制命令
                this.$message.success("已复制成功，可粘贴");
            },300)
        },
    }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog{
    max-width: 640px;
    min-width: 350px;
}
.dialog-content{
    text-align: center;
    line-height: 24px;
    .name{
        font-size: 14px;
        line-height: 20px;
    }
    .code{
        width: 200px;
        height: 200px;
        margin: 15px auto;
        img{
            width: 100%;
            max-width: 100%;
        }
    }
}
.copyTool{
    opacity: 0;
    filter: alpha(opacity=0);
    height: 1px;
}
</style>